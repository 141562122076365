import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react"

import Layout from "../../../components/layout"
import { renderElements } from "../../../components/elements"
import SEO from "../../../components/seo"
import ProductGrid from "../../../components/elements/ProductGrid"

import dummydata from '../../../components/elements/Slider/Promotions/dummydata.json';
import Sort from "../../../components/elements/Sort";

const axios = require('axios');


const PromotionsPage = props => {

    const [products, setProducts] = useState([]);
    const [sortedBy, setSortedBy] = useState('');
    const [showMobileSort, setShowMobileSort] = useState(false);

    // GET PRODUCTS

    useEffect(() => {

        // TODO: Restore AXIOS
        // axios.get(OFFERS_RANDOM_ENDPOINT).then(res => {

        //     console.log("PROMOTIONS", res)

        let offers = [];
        dummydata.result.forEach((offer, key) => {

            offers.push({
                id: offer.id,
                title: offer.Umgangsbezeichnung || offer.Bezeichnung,
                price: offer.VK,
                pricePromotion: offer.VKstatt || offer.VK,
                detail: offer.Bezeichnung2 || '',
            })

        })

        console.log("PROMOTIONS", dummydata)
        setProducts(offers);

        setSortedBy('new');

        // }).catch(err => {
        //     console.log("ERR", err)
        // })


    }, []);

    useEffect(() => {


        const thisProducts = [...products];
        console.log("SORT BY", thisProducts)

        if (thisProducts.length > 0) {

            switch (sortedBy) {
                case 'new':
                    thisProducts.sort((a, b) => {

                        return parseInt(b.id) - parseInt(a.id);

                    });

                    break;

                case 'priceup':
                    thisProducts.sort((a, b) => {

                        let priceA = parseFloat(a.price.replace(',', '.'));
                        let priceB = parseFloat(b.price.replace(',', '.'));

                        return priceA - priceB;

                    });

                    break;

                case 'pricedowm':
                    thisProducts.sort((a, b) => {

                        let priceA = parseFloat(a.price.replace(',', '.'));
                        let priceB = parseFloat(b.price.replace(',', '.'));

                        return priceB - priceA;

                    });

                    break;

            }

            setProducts(thisProducts);

        }


    }, [sortedBy]);


    return (
        <Layout>
            <SEO title={'Angebote'} />
            <div className={`container`} style={{ minHeight: '90vh', paddingTop: 100 }}>
                <div className={`col12`}>
                    <div>
                        <h1>Aktuelle Angebote</h1>

                        <div className={`sortWrapper ${showMobileSort ? "active" : null}`}>
                            <Sort
                                title="Sortieren nach"
                                products={products}
                                value={sortedBy}
                                options={[
                                    {
                                        'title': 'Neuste zuerst',
                                        'id': 'new'
                                    },
                                    {
                                        'title': 'Preis aufsteigend',
                                        'id': 'priceup',
                                    },
                                    {
                                        'title': 'Preis Absteigend',
                                        'id': 'pricedowm'
                                    }
                                ]}
                                onChange={(value) => setSortedBy(value)}
                            />

                        </div>
                        <ProductGrid
                            products={products}
                            perLine={4}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PromotionsPage